<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :addComponent="componentForAdding"
      :editComponent="componentForEditing"
      :delete_url="delete_url"
    >
      <template v-slot:topActions="{ permissions }">
        <div
          class="me-md-2 me-sm-1 mb-1 me-0 pa-0"
          :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
          v-if="permissions('sync')"
        >
          <button
            @click.prevent="envokeSync"
            class="btn btn--export-filter font-weight-bolder"
          >
            <span class="svg-icon">
              <v-icon size="18">mdi-refresh</v-icon>
            </span>
            {{ $t("sync") }}
          </button>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable";
// import ActionsDialog from "@/own/components/warehouseExecutionSystem/WESConfigurationChutes/ActionsDialog";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/wesSplMonitoring.module";
import SwalService from "@/core/services/swal.service";

export default {
  name: "WesSplMonitoring",
  components: { DataTable },
  data: () => ({
    componentForEditing: null,
    componentForAdding: null,
    delete_url: `${process.env.VUE_APP_BASE_URL}/wes/wesChutes/destroy`,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getWESSPLMONITORTableFilters",
      getTableState: "getWESSPLMONITORTableState",
      getTableData: "getWESSPLMONITORTableData",
      getTableHeaders: "getWESSPLMONITORTableHeaders",
      getTableProperties: "getWESSPLMONITORTableProperties",
      getTableExportUrl: "getWESSPLMONITORExportUrl",
      getTablePermissions: "getWESSPLMONITORTablePermissions",
      getUploadType: "getWESSPLMONITORSUploadType",
    },
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {});
    },
    envokeSync() {
      this.$store.commit(SET_PAGE_LOADING, true);
      setTimeout(() => {
        SwalService.successMessage({
          title: SwalService.titles.success,
          html: this.$t("items_synced_successfully"),
        });
        this.$store.commit(SET_PAGE_LOADING, false);
      }, 2000);
    },
  },
  computed: {
    isDataLoaded: function () {
      return this.$store.getters[this.store_names.getTableData];
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
};
</script>
